import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo/logo.png";

const Navigation = () => {
    const location = useLocation();

    const handleClick = (event, target) => {
        event.preventDefault();
        const element = document.getElementById(target);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleLinkClick = (event, target) => {
        if (location.pathname === "/") {
            event.preventDefault();
            handleClick(event, target);
        }
    };

    return (
        <nav className="navbar is-dark">
            <div className="container">
                <div className="navbar-brand">
                    <Link className="navbar-item" to="/">
                        <img src={logo} alt="HotshotCWS Logo" />
                    </Link>
                </div>
                <div className="navbar-menu">
                    <div className="navbar-end">
                        <Link className="navbar-item" to="/" onClick={(e) => handleLinkClick(e, "services")}>
                            Services
                        </Link>
                        <Link className="navbar-item" to="/" onClick={(e) => handleLinkClick(e, "about")}>
                            Company
                        </Link>
                        <Link className="navbar-item" to="/contact">
                            Contact
                        </Link>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navigation;
