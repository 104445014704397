import React from "react";

const AboutSection = () => {
    return (
        <section id="about" className="section">
            <div className="container">
                <h2 className="title">About HotshotCWS</h2>
                <p className="py-2">
                    Hotshot Computer and Web Services is a full-service technology company that has been providing
                    exceptional services to clients since 2012. We offer a range of customized solutions to help
                    individuals and businesses optimize their performance and increase productivity.
                </p>
                <p className="py-2">
                    Our team of expert technicians specializes in building custom systems, including desktop
                    workstations, development servers, and network installations. We also provide software development
                    services for desktop, mobile, and web platforms, as well as consultation services for remote work
                    and media production.
                </p>
                <p className="py-2">
                    Our company prides itself on delivering high-quality services that meet our clients' unique needs and
                    exceed their expectations. Whether you're a professional developer, creative professional, or business
                    owner, we have the expertise to provide tailored solutions that can help you succeed.
                </p>
            </div>
        </section>
    );
};

export default AboutSection;
