import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './HeroSlider.css';

import sliderImage1 from '../assets/slider/slider-image-1.jpg';
import sliderImage2 from '../assets/slider/slider-image-2.jpg';
import sliderImage3 from '../assets/slider/slider-image-3.jpg';

const HeroSlider = () => {
    const previousSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
    };

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    };

    const slides = [
        {
            id: 1,
            image: sliderImage1,
            headerText: 'Custom Technology Solutions for Individuals and Businesses',
            subheaderText: 'Expertly crafted custom computers, software development, and network solutions that meet your unique needs.',
        },
        {
            id: 2,
            image: sliderImage2,
            headerText: 'Remote Work and Collaboration Made Easy',
            subheaderText: 'Tailored solutions for optimizing remote work, including communication tools, project management systems, and cyber-security measures.',
        },
        {
            id: 3,
            image: sliderImage3,
            headerText: 'Enhance Your Digital Presence with Professional Media Production',
            subheaderText: 'Top-notch live streaming and video production services that help businesses stand out in a crowded digital landscape.',
        },
    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, 6000);
        return () => clearInterval(timer);
    }, [slides.length]);


    return (
        <section
            className="hero is-fullheight-with-navbar"
            style={{
                backgroundImage: `url(${slides[currentSlide].image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <div className="hero-body">
                <div className="container">
                    <TransitionGroup className="slider-content">
                        {slides.map((slide, index) => (
                            <CSSTransition
                                key={slide.id}
                                timeout={800}
                                classNames="slide"
                                unmountOnExit
                                appear
                                exit
                            >
                                <div
                                    className={`slide-item ${currentSlide === index ? 'active' : ''}`}
                                    style={{ display: currentSlide === index ? 'block' : 'none' }}
                                >
                                    <h2 className="title is-1" style={{ textShadow: '1px 1px 2px rgba(0,0,0,0.7)' }}>
                                        {slide.headerText}
                                    </h2>
                                    <h3 className="subtitle" style={{ textShadow: '1px 1px 2px rgba(0,0,0,0.7)' }}>
                                        {slide.subheaderText}
                                    </h3>
                                </div>
                            </CSSTransition>
                        ))}
                    </TransitionGroup>
                </div>
            </div>
            <div className="slider-arrows">
                <button className="slider-arrow left" onClick={previousSlide}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button className="slider-arrow right" onClick={nextSlide}>
                    <FontAwesomeIcon icon={faArrowRight} />
                </button>
            </div>
        </section>
    );
};

export default HeroSlider;
