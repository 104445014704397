import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navigation from "./Navigation";
import Footer from "./Footer";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [recaptcha, setRecaptcha] = useState(null);
    const [recaptchaError, setRecaptchaError] = useState(false);
    const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
    const navigate = useNavigate();


    const handleRecaptchaChange = (value) => {
        setRecaptcha(value);
        setRecaptchaError(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (recaptcha === null) {
            setRecaptchaError(true);
            return;
        }

        const formData = {
            name,
            email,
            subject,
            message,
            recaptcha,
        };

        try {
            await axios.post(
                "/.netlify/functions/submit-form",
                formData,
                { timeout: 10000 }
            );
            navigate('/');
        } catch (error) {
            console.error('Error submitting the form:', error);
        }
    };

    // Retrieve the subject URL parameter using the useLocation hook
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const subjectParam = queryParams.get("subject");

    // Set the subject line using the useEffect hook
    useEffect(() => {
        setSubject(subjectParam);
    }, [subjectParam]);

    useEffect(() => {
        setRecaptchaLoaded(true);
    }, []);

    return (
        <>
            <Navigation />
            <section className="section">
                <div className="container">
                    <h2 className="title">Contact Us</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="field">
                            <label className="label">Name</label>
                            <div className="control">
                                <input
                                    className="input"
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Email</label>
                            <div className="control">
                                <input
                                    className="input"
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Subject</label>
                            <div className="control">
                                <input
                                    className="input"
                                    type="text"
                                    placeholder={subjectParam ? `I would like to discuss ${subjectParam}` : "Subject"}
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Message</label>
                            <div className="control">
              <textarea
                  className="textarea"
                  placeholder="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
              ></textarea>
                            </div>
                        </div>
                        {recaptchaLoaded && (
                            <div className="field">
                                <div className="control">
                                    <ReCAPTCHA
                                        sitekey="6Ld9tu4lAAAAAFpQFj_nGr7eIUlipa07m_lHwivE"
                                        onChange={handleRecaptchaChange}
                                        onloadCallback={() => setRecaptchaLoaded(true)}
                                    />
                                    {recaptchaError && (
                                        <p className="help is-danger">Please complete the reCAPTCHA.</p>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="field">
                            <div className="control">
                                <button className="button is-primary" type="submit">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default ContactForm;
