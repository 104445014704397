import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faNetworkWired,
    faCogs,
    faCode,
    faRobot,
    faLaptopHouse,
    faVideo,
    faAngleLeft
} from "@fortawesome/free-solid-svg-icons";
import './ServicesGrid.css';

const ServicesGrid = () => {
    const navigate = useNavigate();
    const services = [
        {
            title: "Computer Build Services",
            icon: faCogs,
            description:
                "We specialize in building custom computers for desktop workstations, development servers, and TrueNAS " +
                "and UNRAID installations. We also provide PC repair and system support services to keep your systems" +
                " running smoothly. Our expert technicians work with you to understand your specific needs and build a" +
                " custom system that meets your requirements. Whether you're a professional developer, a creative" +
                " professional, or a gamer, we can build a system that meets your needs and exceeds your expectations." +
                " Plus, we provide ongoing support to ensure your systems are always up and running, so you can focus" +
                " on your work or play without interruption.",
        },
        {
            title: "Home/Business Networking",
            icon: faNetworkWired,
            description:
                "Our Home/Business Networking service offers consultation for setting up efficient and secure networks" +
                " for homes and businesses. We work closely with our clients to identify their needs and provide" +
                " solutions using state-of-the-art equipment. Our experienced team sources the most qualified technicians" +
                " in the area to ensure seamless installation and configuration of the network. From home Wi-Fi to" +
                " complex business networks, our team provides expert guidance and support throughout the entire process." +
                " Our goal is to ensure that our clients have reliable and secure networks that meet their unique needs.",
        },
        {
            title: "Software Development",
            icon: faCode,
            description:
                "Our software development service offers custom application development for desktop, mobile, and web" +
                " platforms. With 15 years of experience, we have catered to clients globally, delivering custom Drupal," +
                " Python, ReactJS, and full-stack development services. Our team of experts specializes in developing " +
                "custom e-commerce applications and marketplaces with Stripe and Drupal Commerce, providing solutions" +
                " for unique product and shipping needs. We also excel in local development tools and custom integrations" +
                " with third-party APIs. Trust us to deliver tailored solutions to your software development needs," +
                " backed by our extensive experience and commitment to quality.",
        },
        {
            title: "AI Prompt Engineering",
            icon: faRobot,
            description:
                "Our AI Prompt Engineering service specializes in creating custom training datasets for precision tuning" +
                " with OpenAI ChatGPT. We help our clients improve their AI models by providing them with high-quality" +
                " training data sets that are specifically tailored to their needs. In addition, we offer consultation" +
                " services on how to optimize business workflows using available AI tools, helping companies streamline" +
                " their processes and improve their bottom line. Our team of experts has years of experience in the field" +
                " of AI, and we work closely with our clients to deliver solutions that meet their unique needs and" +
                " exceed their expectations.",
        },
        {
            title: "Remote Work",
            icon: faLaptopHouse,
            description:
                "Our \"Remote Work Consultation\" service provides customized solutions for professionals and" +
                " organizations to optimize their work performance while working remotely. We specialize in the web" +
                " development industry, providing consultation services for distributed teams. Our team of experts will" +
                " assess your current workflow and help you identify areas for improvement to increase productivity" +
                " and collaboration. We provide tailored solutions that fit your unique needs and goals, including remote" +
                " work policies, communication tools, project management systems, and cyber-security measures. Our goal" +
                " is to help you succeed in the new world of remote work by providing the support and guidance you need" +
                " to thrive.",
        },
        {
            title: "Live Steaming / Video Production",
            icon: faVideo,
            description:
                "Our \"Live Streaming / Video Production\" service caters to professionals and companies who want to " +
                "improve their presence on live streaming and video platforms. We offer consultation services to help" +
                " configure and set up studios for media production, as well as provide insights on workflows to optimize" +
                " production efficiency and quality. Our experienced team of professionals provides expert guidance on" +
                " camera selection, lighting, audio, and more to ensure that your live streaming and video content" +
                " stands out from the rest. With our service, you can be sure that your live streaming and video" +
                " production needs will be met with top-notch quality and precision.",
        },
    ];

    const [expandedService, setExpandedService] = useState(null);
    const [showServices, setShowServices] = useState(true);

    const handleExpandService = (index) => {
        setExpandedService(index);
        setShowServices(false);
    };

    const handleCollapseService = () => {
        setExpandedService(null);
        setShowServices(true);
    };

    return (
        <div id="services" className="section">
            <div className="container">
                <div className="columns is-multiline is-centered">
                    {showServices ? (
                        services.map((service, index) => (
                            <div
                                key={index}
                                className={`column has-text-centered is-4`}
                                onClick={() => handleExpandService(index)}
                            >
                                <div className="box">
                                    <div className="icon">
                                        <FontAwesomeIcon icon={service.icon} size="2x" />
                                    </div>
                                    <h4 className="title is-4">{service.title}</h4>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className={`column has-text-centered is-12`}>
                            <div className="box">
                                <div className="icon-and-title">
                                    <div className="icon">
                                        <FontAwesomeIcon
                                            icon={services[expandedService].icon}
                                            size="2x"
                                        />
                                    </div>
                                    <h4 className="title is-4">{services[expandedService].title}</h4>
                                </div>
                                <div className="expanded-service">
                                    <p className="pt-2 has-text-left">{services[expandedService].description}</p>
                                    <div className="pt-3 columns is-centered container">
                                        <div className="column is-one-fifth">
                                            <button className="button is-text" onClick={handleCollapseService}>
                                                <FontAwesomeIcon
                                                    icon={faAngleLeft}
                                                    className="back-button pr-1"
                                                    size="1x"
                                                    onClick={handleCollapseService}
                                                />
                                                Back
                                            </button>
                                        </div>
                                        <div className="column is-one-fifth">
                                            <a
                                                href="#contact"
                                                className="button is-dark"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    navigate("/contact", { state: { subject: services[expandedService].title } });
                                                }}
                                            >
                                                Contact us about {services[expandedService].title}
                                            </a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ServicesGrid;
