import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
    return (
        <footer className="footer has-background-dark">
            <div className="content has-text-centered">
                <div className="social-icons">
                    <a className="px-3" href="https://www.linkedin.com/company/hotshotcws/" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} size="2x" className="has-text-white" />
                    </a>
                    <a className="px-3" href="https://twitter.com/hotshotcws" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faTwitter} size="2x" className="has-text-white" />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
