import React from "react";
import Navigation from "./Navigation";
import HeroSlider from "./HeroSlider";
import ServicesGrid from "./ServicesGrid";
import AboutSection from "./AboutSection";
import Footer from "./Footer";
import ScrollContext from '../contexts/ScrollContext';

const Home = () => {
    const scrollToContact = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth",
        });
    };

    return (
        <ScrollContext.Provider value={{ scrollToContact }}>
            <Navigation />
            <HeroSlider />
            <ServicesGrid />
            <AboutSection />
            <Footer />
        </ScrollContext.Provider>
    );
};

export default Home;
